<template>
  <v-form
    ref="form"
    v-model="valid"
  >
    <v-card
      elevation="24"
    >
      <v-card-title class="primary" style="color: white !important;">
        ارسال وتفعيل ورفض الارقام
      </v-card-title>
      <v-container>
        <v-row>
          <v-col
            cols="6"
            md="6"
            sm="6"
          >
            <textarea-autosize
              v-model="form.numbers"
              outlined
              placeholder="ادخل مجموعة الارقام . . ."
              :min-height="300"
              :max-height="350"
              style="width: 100% !important; border-style: dashed"
            />
            <span style="color: red">  ملاحظة :- يتم تفعيل  مجموعة الارقام المرسلة فقط </span>
          </v-col>
          <v-col
            cols="6"
            md="6"
            sm="6"
          >
            <textarea-autosize
              v-model="form.reasons"
              outlined
              placeholder="ادخل مجموعة الاسباب . . ."
              :min-height="300"
              :max-height="350"
              style="width: 100% !important;border-style: dashed; place-content: center"
            />
            <span style="color: red">  ملاحظة :- يتم ادخال  مجموعة الاسباب في حالة الرفض فقط </span>
          </v-col>
        </v-row>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="mr-4"
            color="info"
            :disabled="!valid"
            @click="sentAll()"
          >
            {{ $t('form.sent') }}
          </v-btn>
          <v-btn
            class="mr-4"
            color="success"
            :disabled="!valid"
            @click="activateAll()"
          >
            {{ $t('form.activate') }}
          </v-btn>
          <v-btn
            class="mr-4"
            color="error"
            :disabled="!valid"
            @click="lockedAll()"
          >
            {{ $t('form.locked') }}
          </v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-form>
</template>
<script>
import axios from '@axios'
import toast from '@/store/toast'

export default {
  components: { },
  data: () => ({
    valid: false,
    form: {
      numbers: '',
      reasons: '',
    },

  }),

  computed: {
    numbersArr() {
      return this.form.numbers.split('\n')
    },
    reasonsArr() {
      return this.form.reasons.split('\n')
    },

    rules() {
      const rules = {};
      rules.nameRules = [
        v => !!v || this.$t('form.required', ['الاسم']),
        v => !!v || this.$t('form.required', ['الاسم']),
      ];

      return rules;
    },
  },
  async mounted() {
    this.$refs.form.validate();
  },
  methods: {
    // eslint-disable-next-line consistent-return
    async sentAll() {
      if (this.$refs.form.validate()) {
        if (!this.form.numbers) {
          return 0;
        }
        await axios.post('/phoneNumber/fastUpdate', { data: this.numbersArr, type: 'sent' })
        this.resetForm()
        await toast.dispatch('success', this.$t('form.success.sent'))
      }
    },
    // eslint-disable-next-line consistent-return
    async activateAll() {
      if (this.$refs.form.validate()) {
        if (!this.form.numbers) {
          return 0;
        }
        await axios.post('/phoneNumber/fastUpdate', { data: this.numbersArr, type: 'activate' })
        this.resetForm()
        await toast.dispatch('success', this.$t('form.success.activate'))
      }
    },
    // eslint-disable-next-line consistent-return
    async lockedAll() {
      if (this.$refs.form.validate()) {
        if (!this.form.numbers) {
          return 0;
        }

        if (!this.form.reasons) {
          await toast.dispatch('error', this.$t('form.lockedCauseError'))
          return 0;
        }

        await axios.post('/phoneNumber/fastUpdate', {
          data: {
            numbers: this.numbersArr,
            reasons: this.reasonsArr,
          },
          type: 'locked',
        })
        this.resetForm()
        await toast.dispatch('success', this.$t('form.success.locked'))
      }
    },

    resetForm() {
      if (this.$refs.form) {
        this.$refs.form.reset();
      }
      this.form = {
        numbers: '',
        reasons: '',
      };
    },
  },

}
</script>
